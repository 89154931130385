import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Hero from './components/Hero';
import Features from './components/Features';
import Download from './components/Download';
import Footer from './components/Footer';
import ComingSoon from './components/ComingSoon';

const MainLayout = () => (
  <div className="min-h-screen bg-fatefy-dark text-white">
    <Hero />
    <Features />
    <Download />
    <Footer />
  </div>
);

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/soon" replace />} />
        <Route path="/soon" element={<ComingSoon />} />
        <Route path="/home" element={<MainLayout />} />
        <Route path="/download" element={<Navigate to="/soon" replace />} />
        <Route path="/learn-more" element={<Navigate to="/soon" replace />} />
        <Route path="*" element={<Navigate to="/soon" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App; 