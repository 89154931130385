import React from 'react';
import { motion } from 'framer-motion';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="relative bg-black/50 backdrop-blur-xl border-t border-white/10">
      <div className="max-w-7xl mx-auto px-4 py-16">
        <div className="grid md:grid-cols-4 gap-12 md:gap-8">
          <div>
            <motion.h3 
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              className="font-display text-2xl bg-gradient-to-r from-fatefy-primary to-fatefy-accent bg-clip-text text-transparent mb-4"
            >
              Fatefy
            </motion.h3>
            <p className="text-fatefy-light/60">
              Finding your destiny through meaningful connections.
            </p>
          </div>

          <div>
            <h4 className="font-semibold text-white mb-4">Company</h4>
            <ul className="space-y-3">
              <li><a href="/about" className="text-fatefy-light/60 hover:text-fatefy-primary transition-colors">About</a></li>
              <li><a href="/careers" className="text-fatefy-light/60 hover:text-fatefy-primary transition-colors">Careers</a></li>
              <li><a href="/press" className="text-fatefy-light/60 hover:text-fatefy-primary transition-colors">Press</a></li>
            </ul>
          </div>

          <div>
            <h4 className="font-semibold text-white mb-4">Resources</h4>
            <ul className="space-y-3">
              <li><a href="/blog" className="text-fatefy-light/60 hover:text-fatefy-primary transition-colors">Blog</a></li>
              <li><a href="/help" className="text-fatefy-light/60 hover:text-fatefy-primary transition-colors">Help Center</a></li>
              <li><a href="/safety" className="text-fatefy-light/60 hover:text-fatefy-primary transition-colors">Safety Tips</a></li>
            </ul>
          </div>

          <div>
            <h4 className="font-semibold text-white mb-4">Legal</h4>
            <ul className="space-y-3">
              <li><a href="/privacy" className="text-fatefy-light/60 hover:text-fatefy-primary transition-colors">Privacy Policy</a></li>
              <li><a href="/terms" className="text-fatefy-light/60 hover:text-fatefy-primary transition-colors">Terms of Service</a></li>
              <li><a href="/cookies" className="text-fatefy-light/60 hover:text-fatefy-primary transition-colors">Cookie Policy</a></li>
            </ul>
          </div>
        </div>

        <div className="mt-16 pt-8 border-t border-white/10 text-center text-fatefy-light/40">
          <p>© {currentYear} Fatefy. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 