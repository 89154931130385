import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const Hero = () => {
  const navigate = useNavigate();
  
  return (
    <div className="relative min-h-screen overflow-hidden bg-gradient-to-b from-fatefy-dark via-fatefy-dark to-black">
      {/* Background decorative elements */}
      <div className="absolute inset-0 bg-gradient-dots bg-dots opacity-5"></div>
      <div className="absolute top-20 left-10 w-72 h-72 bg-fatefy-primary/30 rounded-full blur-3xl"></div>
      <div className="absolute bottom-20 right-10 w-96 h-96 bg-fatefy-accent/20 rounded-full blur-3xl"></div>
      
      <div className="relative max-w-7xl mx-auto px-4 pt-20 pb-32">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center"
        >
          <h1 className="font-display text-7xl md:text-8xl font-bold mb-6 bg-gradient-to-r from-fatefy-primary via-fatefy-secondary to-fatefy-accent bg-clip-text text-transparent">
            Find Your Fate
          </h1>
          <p className="text-xl md:text-2xl text-fatefy-light/80 mb-12 max-w-2xl mx-auto">
            Discover meaningful connections with people who share your destiny, powered by AI-driven matchmaking
          </p>
          <div className="flex flex-col sm:flex-row gap-6 justify-center">
            <motion.button 
              onClick={() => navigate('/download')}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="group relative px-8 py-4 rounded-full bg-gradient-to-r from-fatefy-primary to-fatefy-accent overflow-hidden"
            >
              <span className="absolute inset-0 bg-white/20 group-hover:scale-105 transition-transform duration-300"></span>
              <span className="relative text-white font-semibold">Download Now</span>
            </motion.button>
            <motion.button 
              onClick={() => navigate('/learn-more')}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="px-8 py-4 rounded-full border-2 border-fatefy-light/20 text-fatefy-light hover:bg-fatefy-light/10 transition-colors font-semibold"
            >
              Learn More
            </motion.button>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="mt-20 relative"
        >
          {/* Phone mockup with floating animation */}
          <div className="relative max-w-sm mx-auto animate-float">
            <div className="absolute inset-0 bg-gradient-to-r from-fatefy-primary to-fatefy-accent blur-2xl opacity-30"></div>
            <img 
              src="/mockup.png" 
              alt="Fatefy App" 
              className="relative rounded-3xl shadow-2xl"
            />
            
            {/* Floating elements */}
            <motion.div
              animate={{
                y: [0, -10, 0],
                rotate: [0, 5, 0]
              }}
              transition={{
                duration: 4,
                repeat: Infinity,
                repeatType: "reverse"
              }}
              className="absolute -left-16 top-20 bg-white/10 backdrop-blur-xl rounded-2xl p-4 shadow-xl"
            >
              <div className="flex items-center gap-3">
                <div className="w-12 h-12 rounded-full bg-fatefy-primary"></div>
                <div className="text-left">
                  <div className="h-2 w-20 bg-white/50 rounded"></div>
                  <div className="h-2 w-16 bg-white/30 rounded mt-2"></div>
                </div>
              </div>
            </motion.div>

            <motion.div
              animate={{
                y: [0, 10, 0],
                rotate: [0, -5, 0]
              }}
              transition={{
                duration: 5,
                repeat: Infinity,
                repeatType: "reverse"
              }}
              className="absolute -right-16 bottom-20 bg-white/10 backdrop-blur-xl rounded-2xl p-4 shadow-xl"
            >
              <div className="flex items-center gap-3">
                <div className="w-12 h-12 rounded-full bg-fatefy-accent"></div>
                <div className="text-left">
                  <div className="h-2 w-20 bg-white/50 rounded"></div>
                  <div className="h-2 w-16 bg-white/30 rounded mt-2"></div>
                </div>
              </div>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Hero; 