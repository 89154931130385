import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const Download = () => {
  const navigate = useNavigate();

  return (
    <section className="relative py-32 overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-fatefy-dark to-black"></div>
      <div className="absolute inset-0 bg-gradient-dots bg-dots opacity-5"></div>
      
      {/* Decorative blurs */}
      <div className="absolute top-1/4 left-0 w-96 h-96 bg-fatefy-primary/20 rounded-full blur-3xl"></div>
      <div className="absolute bottom-0 right-0 w-96 h-96 bg-fatefy-accent/20 rounded-full blur-3xl"></div>

      <div className="relative max-w-7xl mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center"
        >
          <h2 className="font-display text-5xl md:text-6xl font-bold mb-6 bg-gradient-to-r from-fatefy-primary via-fatefy-secondary to-fatefy-accent bg-clip-text text-transparent">
            Ready to Find Your Fate?
          </h2>
          <p className="text-xl text-fatefy-light/80 mb-12 max-w-2xl mx-auto">
            Join thousands of people who have already found their perfect match
          </p>

          <div className="flex flex-col sm:flex-row gap-6 justify-center">
            <motion.a
              onClick={() => navigate('/soon')}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="group relative"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-fatefy-primary to-fatefy-accent blur-md opacity-50 group-hover:opacity-75 transition-opacity"></div>
              <div className="relative px-8 py-4 bg-gradient-to-r from-fatefy-primary to-fatefy-accent rounded-full flex items-center gap-3">
                <span className="text-2xl">🍎</span>
                <div className="text-left">
                  <div className="text-xs text-white/80">Download on the</div>
                  <div className="text-white font-semibold">App Store</div>
                </div>
              </div>
            </motion.a>

            <motion.a
              href="#"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="group relative"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-fatefy-accent to-fatefy-primary blur-md opacity-50 group-hover:opacity-75 transition-opacity"></div>
              <div className="relative px-8 py-4 bg-gradient-to-r from-fatefy-accent to-fatefy-primary rounded-full flex items-center gap-3">
                <span className="text-2xl">🤖</span>
                <div className="text-left">
                  <div className="text-xs text-white/80">GET IT ON</div>
                  <div className="text-white font-semibold">Google Play</div>
                </div>
              </div>
            </motion.a>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default Download; 