import React from 'react';
import { motion } from 'framer-motion';

const Features = () => {
  const features = [
    {
      title: "AI-Powered Matching",
      description: "Our advanced algorithm learns your preferences to find your perfect match",
      icon: "✨",
      gradient: "from-fatefy-primary to-fatefy-secondary"
    },
    {
      title: "Verified Profiles",
      description: "Meet genuine people with our advanced verification system",
      icon: "🛡️",
      gradient: "from-fatefy-secondary to-fatefy-accent"
    },
    {
      title: "Meaningful Connections",
      description: "Focus on real relationships that matter, not just casual swipes",
      icon: "❤️",
      gradient: "from-fatefy-accent to-fatefy-primary"
    }
  ];

  return (
    <section className="relative py-32 bg-fatefy-dark overflow-hidden">
      {/* Background decorative elements */}
      <div className="absolute inset-0 bg-gradient-dots bg-dots opacity-5"></div>
      <div className="absolute top-0 left-1/4 w-96 h-96 bg-fatefy-accent/10 rounded-full blur-3xl"></div>
      
      <div className="max-w-7xl mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-20"
        >
          <h2 className="font-display text-5xl md:text-6xl font-bold mb-6 bg-gradient-to-r from-fatefy-primary via-fatefy-secondary to-fatefy-accent bg-clip-text text-transparent">
            Why Choose Fatefy
          </h2>
          <p className="text-xl text-fatefy-light/70 max-w-2xl mx-auto">
            Experience dating reimagined with cutting-edge technology and genuine connections
          </p>
        </motion.div>

        <div className="grid md:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              whileHover={{ y: -5 }}
              className="relative group"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-fatefy-primary to-fatefy-accent opacity-0 group-hover:opacity-10 rounded-2xl transition-opacity duration-300"></div>
              <div className="relative bg-white/5 backdrop-blur-xl p-8 rounded-2xl border border-white/10">
                <div className={`text-5xl mb-6 bg-gradient-to-r ${feature.gradient} bg-clip-text text-transparent`}>
                  {feature.icon}
                </div>
                <h3 className="text-2xl font-semibold text-white mb-4">{feature.title}</h3>
                <p className="text-fatefy-light/70">{feature.description}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features; 